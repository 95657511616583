import Vue from "vue";
import Vuex from "vuex";
import getters from "@g/store/getters";
import app from "@g/store/modules/app";
import user from "@g/store/modules/user";
import branch from "@/store/modules/branch";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    branch
  },
  getters
});

export default store;
