import Vue from "vue";
import VueRouter from "vue-router";
import adminRoutes from "./modules/admin";
import pagesRoutes from "./modules/pages";
Vue.use(VueRouter);

const routes = [
    {
        name: "LoginWithMsal",
        path: "/login-with-msal/:role",
        component: () => import("@/views/pages/LoginWithMsal"),
    },
    {
        path: "/pages",
        component: () => import("@/layouts/pages/Index"),
        children: pagesRoutes,
    },
    {
        path: "/lecturer",
        component: () => import("@/layouts/dashboard/Index"),
        children: adminRoutes,
        redirect: "/lecturer/original-exams",
    },
    {
        path: "/logout",
        name: "Logout",
    },
    {
        path: "/",
        redirect: "/lecturer",
    },
    {
        path: "/admin",
        redirect: "/lecturer",
    },
    {
        path: "/supervisor",
        redirect: "/lecturer/supervisors/monitoring",
    },
    {
        path: "/canborade",
        redirect: "/admin/original-exams-by-officer",
    },
    {
        path: "/administrator",
    },
    {
        path: "*",
        redirect: "/404",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
