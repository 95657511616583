export default [
    {
        path: "original-exams",
        name: "Đề gốc",
        component: () => import("@/views/admin/original-exams/index"),
        meta: {
            roles: ["Sa", "Le"],
        },
    },
    {
        path: "exam-generation",
        name: "Sinh đề",
        component: () => import("@/views/admin/exam-generation/index"),
        meta: {
            roles: ["Sa", "Le"],
        },
    },
    {
        path: "exams",
        name: "Đề thi",
        component: () => import("@/views/admin/exams/index"),
        meta: {
            roles: ["Sa", "Le"],
        },
    },
    {
        path: "original-exams/:id/questions",
        name: "Câu hỏi",
        component: () => import("@/views/admin/original-exams/show"),
        meta: {
            roles: ["Sa", "Le"],
        },
    },
    {
        name: "Kíp thi",
        path: "examinations",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/examinations/index"),
    },
    {
        name: "Học phần",
        path: "subjects",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@g/views/admin/subject/index"),
    },
    {
        name: "Sinh viên Kíp thi",
        path: "examinations/:id/students",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/student/index"),
    },
    {
        name: "Điều khiển Phòng thi",
        path: "examinations/:id/room",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/examinations/Room"),
    },
    {
        name: "Kết quả thi",
        path: "examinations/:id/result",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/examinations/Result"),
    },
    {
        name: "Giám sát thi",
        path: "exam-monitoring",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/exam-monitoring/index"),
    },

    {
        name: "Bài thi",
        path: "forms",
        meta: {
            roles: ["Sa", "Le"],
        },
        component: () => import("@/views/admin/forms/index"),
    },
    {
        name: "Giám thị coi thi",
        path: "supervisors/monitoring",
        component: () => import("@/views/admin/exam-monitoring/Monitoring"),
        meta: {
            roles: [],
        },
    },
];
