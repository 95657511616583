import axios from "axios";
import store from "@/store";

const service = axios.create({
  baseURL: "/api",
  withCredentials: true,
  timeout: 30000
});

service.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      Organization: store.state.user.organizationId
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 422) {
      store.dispatch("app/showSnackbar", {
        text: error.response.data.errors[0].message,
        type: "error",
        timeout: 2000
      });
    } else if (error.response.status === 401) {
      store.dispatch("app/showSnackbar", {
        text: "Bạn đã đăng xuất",
        type: "error",
        timeout: 2000
      });
      store.dispatch("user/removeAuth").then(() => {
        location.reload();
      });
    } else if (error.response.status === 404) router.push("/404");
    else
      store.dispatch("app/showSnackbar", {
        text: error.response.data.error.message,
        type: "error",
        timeout: 2000
      });

    return Promise.reject(error);
  }
);

// window.requestApi = service; // For DEBUG

export default service;
